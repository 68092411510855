import { useEffect, useState } from 'react';

import {
  AdObj,
  debugLog,
  isPremiumNativeAd,
  isTakeover,
  NativeAdObj,
  PlacementId,
  RecommendationCategories,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { injectTakeoverTemplate } from '@client/xandr/components/takeover/util';
import { getTagConfig } from '@client/xandr/tag';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { checkForNoBidCreatives } from '@client/xandr/mediationNoBidHandler';
import {
  defineTag,
  getDebouncedLoadTagsFunction,
  loadAstTags,
  onAdAvailable,
  onAdBadRequest,
  onAdLoaded,
  onAdLoadedMediated,
  onAdNoBid,
  onAdNoBidMediated,
  onAdRequested,
  XandrLifecycleEvents
} from '@client/xandr/xandr';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { setPageOptsInXandr } from '@client/xandr/setPageOpts';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';

import { getConsentOrSubscribe } from '../utils/consent';
import { $config, $initialState } from '../atoms/config';

export type SetupAdProps = {
  placementId: PlacementId; // The actual placementId xandr uses
  targetId: string; // Used for cycling and actual targeting
  containerId: string; // The container the ad is loaded into from renderAd()
  categories?: RecommendationCategories;
};

export function useSetupAd({
  placementId,
  targetId,
  categories
}: SetupAdProps) {
  const [adObj, setAdObj] = useState<AdObj | null>(null);

  const tagConfig = getTagConfig(
    placementId as PlacementId,
    targetId,
    categories
  );

  useEffect(() => {
    function setCreativeType(_adObj: AdObj) {
      const deviceType = $config.get()?.deviceType;

      let creativeType = _adObj?.adType;

      if (isTakeover(_adObj, deviceType)) {
        creativeType = 'takeover';
        injectTakeoverTemplate(placementId);
      }

      if (isPremiumNativeAd(adObj as NativeAdObj)) {
        creativeType = 'sponsored-content';
      }
      updatePlacementKeyValueById(placementId, 'creativeType', creativeType);
    }
    onAdRequested(targetId, () => {
      updateMetricByKey(placementId, 'requested');
      debugTools(placementId, `Requested ${targetId}`, 'Requested');
    });

    onAdAvailable(targetId, (_adObj: AdObj) => {
      if (checkForNoBidCreatives(placementId, _adObj.creativeId)) {
        debugTools(
          placementId,
          `noMediationBid for ${targetId} with Creative ID: ${_adObj.creativeId}`,
          'No bid'
        );
        updateMetricByKey(placementId, 'noBid');
        updatePlacementKeyValueById(placementId, 'status', 'error');
        return;
      }
      updateMetricByKey(placementId, 'loaded');
      setCreativeType(_adObj);
      updatePlacementKeyValueById(placementId, 'status', 'available');

      debugTools(
        placementId,
        `adAvailable: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Available',
        _adObj
      );
      debugLog('adAvailable:', { adObj: _adObj });
      setAdObj(_adObj);
    });

    onAdLoaded(targetId, (_adObj: AdObj) => {
      debugTools(
        placementId,
        `adLoaded: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Loaded'
      );
      setCreativeType(_adObj);
      updatePlacementKeyValueById(placementId, 'status', 'loaded');
      setAdObj(_adObj);
    });

    onAdLoadedMediated(targetId, (_adObj: AdObj) => {
      updatePlacementKeyValueById(placementId, 'status', 'loaded');

      debugTools(
        placementId,
        `adLoadedMediated: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Loaded'
      );
    });

    onAdBadRequest(targetId, () => {
      updateMetricByKey(placementId, 'badRequest');
      debugTools(placementId, `adBadRequest for ${targetId}`, 'Bad request');
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    onAdNoBid(targetId, () => {
      updateMetricByKey(placementId, 'noBid');
      debugTools(placementId, `adNoBid for ${targetId}`, 'No bid');
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    onAdNoBidMediated(targetId, () => {
      updateMetricByKey(placementId, 'noBid');
      debugTools(placementId, `adNoBidMediated for ${targetId}`, 'No bid');
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    getConsentOrSubscribe((permissionValue) => {
      debugLog('getConsentOrSubscribe ', { permissionValue });
      setPageOptsInXandr(permissionValue);
      if (tagConfig) {
        defineTag(tagConfig);
        if (
          isFeatureEnabled(UNLEASH_FEATURE_NAME.disableXandrLoadTagsDebounce)
        ) {
          loadAstTags();
        } else {
          getDebouncedLoadTagsFunction()();
        }
      }
    });

    const cleanupEvents = () => {
      if (!window.apntag?.anq) return;

      [
        XandrLifecycleEvents.AD_REQUESTED,
        XandrLifecycleEvents.AD_AVAILABLE,
        XandrLifecycleEvents.AD_LOADED,
        XandrLifecycleEvents.AD_NO_BID,
        XandrLifecycleEvents.AD_BAD_REQUEST
      ].forEach((eventType) => {
        window.apntag.anq.push(() =>
          window.apntag?.offEvent(eventType, targetId)
        );
      });
    };

    return () => {
      setAdObj(null);
      cleanupEvents();
    };
  }, [targetId]);

  return { adObj };
}

/**
 * ===========================================================================
 * The following functions are purely used for debugging and logging purposes.
 */

function setLocalEntry(
  placementId: PlacementId,
  localEntryText: string,
  adObj?: AdObj
) {
  if ($initialState.get()?.env === 'local') {
    const localEntry = document.getElementById(`${placementId}-local-entry`);
    if (localEntry) {
      const sizes =
        adObj?.source === 'rtb' ? `${adObj.width}x${adObj.height}` : '';
      localEntry.innerHTML += `<p class='m-0 text-12'> - ${localEntryText} ${
        adObj ? `(${adObj.adType}, ${sizes})` : ''
      }</p>`;
    }
  }
}

function debugTools(
  placementId: PlacementId,
  logEntry: string,
  localEntry: string,
  adObj?: AdObj
) {
  debugLog(logEntry);
  advtPerformance.markXandrEvents(logEntry);
  setLocalEntry(placementId, localEntry, adObj);
}

import React, { useRef } from 'react';

import {
  LayoutType,
  NativeAdObj,
  PlacementId,
  Vertical
} from '@schibsted-nmp/advertising-shared';
import { useLoadNativeAd } from '@client/core/hooks';
import { $config } from '@client/core/atoms/config';

import NativeCogwheel from './parts/NativeCogwheel';
import { NativeAdSwitch } from './NativeAdSwitch';

export type Props = {
  adObj: NativeAdObj;
  targetId: string;
  placementId: PlacementId;
  className?: string;
  layoutType?: LayoutType;
  pageType?: string;
  vertical?: string;
  isRecircAd?: boolean;
};

function getExtraInset(
  pageType: string,
  vertical: Vertical,
  isRecircAd: boolean,
  layoutType?: LayoutType
): string {
  if (layoutType === 'list') return '0';
  if (isRecircAd && pageType === 'object') return '8';
  const extraCogwheelInset: Vertical[] = ['car', 'mobility'];
  return extraCogwheelInset?.includes(vertical) ? '10' : '0';
}

export default function NativeAdComponent(props: Props) {
  const { targetId, adObj, layoutType } = props;
  const { vertical, pageType } = $config.get();

  const ref = useRef(null);
  useLoadNativeAd(adObj, targetId, props.placementId, ref);

  const isRecircAd =
    targetId.includes('recirc') || targetId.includes('ad-stream');
  return (
    <div className="h-full" id={targetId} ref={ref as any}>
      <NativeAdSwitch
        {...props}
        vertical={vertical}
        pageType={pageType}
        isRecircAd={isRecircAd}
      />
      <NativeCogwheel
        extraInset={getExtraInset(pageType, vertical, isRecircAd, layoutType)}
        adObj={adObj}
      />
    </div>
  );
}

import { deepMap } from '@podium/store';
import {
  AdConfigIntermingle,
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index';

export const $placementsMap = deepMap<Record<string, ClientAdPlacement>>(
  AtomChannels.Podlet,
  AtomTopics.PlacementsMap,
  {}
);

export function updatePlacementKeyValueById(
  placementId: PlacementId,
  updateKey: keyof ClientAdPlacement,
  updateValue: any
) {
  $placementsMap.setKey(`${placementId}.${updateKey}`, updateValue);
}

export function getPlacementList(): ClientAdPlacement[] {
  return Object.values($placementsMap.get())?.filter(
    (placement) => placement?.placementId
  );
}

export function resetRefreshPlacements() {
  getRefreshPlacements().forEach((placement) => {
    updatePlacementKeyValueById(placement.placementId, 'status', 'pending');
  });
}

export function getRefreshPlacements() {
  return getPlacementList().filter(
    (placement) => placement.status === 'refresh'
  );
}

export function getInterminglePlacements() {
  return getPlacementList().filter((placement) => {
    const intermingle = placement.intermingle as AdConfigIntermingle;
    if (!intermingle) return false;

    // Recirc ads are not loaded as intermingle placements anymore. Instead, they are set up by the recommendation API
    // and rendered from the components in advertising-companion (AdvertisingWebComponent).
    const isRecircAd =
      placement.placementId.includes('recirc') ||
      placement.placementId.includes('ad-stream');
    if (isRecircAd) return false;
    return Boolean(intermingle.list && intermingle.grid);
  });
}

import { throttle } from 'throttle-debounce';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { PlacementId } from '@schibsted-nmp/advertising-shared';
import {
  fetchAllElements,
  fetchElementById,
  getDocumentHeight,
  querySelect
} from '@client/core/utils/dom/domUtils';
import { $config } from '@client/core/atoms/config';

const BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY = '.banners__sticky-container';
const FOOTER_ELEM_QUERY = 'finn-footer';
const offset = 100;
const FOOTER_HEIGHT_PX = 500;
let bannerStickyContainers: NodeListOf<HTMLElement> | null = null;
const activeAdnamiPlacementIds: string[] = [];

const fetchStickyContainer = () => {
  if (!bannerStickyContainers) {
    bannerStickyContainers = fetchAllElements(
      BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY
    );
  }
};

const setSkyscrapersStickyHeight = () => {
  const hasTwoSkyscraperRows = $config
    .get()
    .placements.some(
      (placement) =>
        placement.placementId === PlacementId.Right2 ||
        placement.placementId === PlacementId.Left2
    );
  const firstRowStickyContainers = fetchAllElements('.banners__sticky--1');
  const secondRowStickyContainers = fetchAllElements('.banners__sticky--2');

  if (hasTwoSkyscraperRows) {
    firstRowStickyContainers.forEach((container) => {
      container.style.height = `1080px`;
    });
    secondRowStickyContainers.forEach((container) => {
      container.style.height = `calc(100% - 1080px)`;
      container.style.marginTop = `1080px`;
    });
  } else {
    firstRowStickyContainers.forEach((container) => {
      container.style.height = `100%`;
    });
    secondRowStickyContainers.forEach((container) => {
      container.style.height = `0px`;
    });
  }
};

const setSkyscraperContainerHeight = () => {
  setSkyscrapersStickyHeight();
  const collidingElements: HTMLElement[] = activeAdnamiPlacementIds
    .map((placementId) => fetchElementById(placementId))
    .filter((placement) => placement !== null);

  const footer = querySelect(FOOTER_ELEM_QUERY);
  if (footer) collidingElements.push(footer);

  const firstCollisionElement =
    collidingElements.sort(
      (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
    )[0] || null;

  if (firstCollisionElement) {
    setBannerStickyContainerHeight(
      firstCollisionElement.getBoundingClientRect().top +
        window.scrollY -
        offset
    );
  } else {
    // fallback. eg. if footer element was not found
    setBannerStickyContainerHeight(
      getDocumentHeight() - FOOTER_HEIGHT_PX - offset
    );
  }
};

const setBannerStickyContainerHeight = (newHeight: number) => {
  fetchStickyContainer();
  if (!bannerStickyContainers) return;
  bannerStickyContainers.forEach((container) => {
    container.style.height = `${newHeight}px`;
  });
};

export const publishAdnamiPlacement = (containerId: string) => {
  messageBus.publish(events.PODLET.channel, 'adnami', containerId);
};

const listenToAdnamiPlacements = () => {
  messageBus.subscribe(events.PODLET.channel, 'adnami', (e) => {
    const placementId = e.payload as PlacementId;
    if (
      placementId &&
      !activeAdnamiPlacementIds.some((placements) => placements === placementId)
    ) {
      activeAdnamiPlacementIds.push(placementId);
    }
  });
};

export const initiateHandleStickySkyscrapers = () => {
  listenToAdnamiPlacements();
  setSkyscraperContainerHeight();
  window.addEventListener(
    'scroll',
    throttle(200, setSkyscraperContainerHeight),
    { passive: true }
  );
};

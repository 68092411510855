"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSizesByMediaType = getSizesByMediaType;
function getSizesByMediaType(mediaTypes) {
    var sizes = [];
    mediaTypes.forEach(function (size) {
        if (size.type === 'display' || size.type === 'video') {
            sizes.push([size.width, size.height]);
        }
        else if (size.type === 'native') {
            sizes.push('fluid');
        }
    });
    return sizes;
}

import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import {
  AfsPageOptions,
  debugLog,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { PlacementId } from '@schibsted-nmp/advertising-shared/src/types/adPlacement';
import { updateMetricByKey } from '@client/core/atoms/metrics';

export function triggerAfsRequest({
  container,
  ...afsPageOptions
}: AfsPageOptions) {
  if (
    afsPageOptions.query &&
    !isFeatureEnabled(UNLEASH_FEATURE_NAME.disableAfsPlacements)
  ) {
    debugLog(`Triggering AFS request for container: ${container}`);
    try {
      if (window.location.hostname.includes('local')) {
        renderAFSPlaceholder(container);
      }

      window._googCsa('ads', afsPageOptions, {
        container,
        adLoadedCallback: (...args) => {
          updateMetricByKey(container, 'loaded');
          debugLog(`Arguments from adLoadedCallback: ${args}`);
        }
      });
      updateMetricByKey(container, 'requested');
    } catch (error) {
      debugLog('Error in google ad sense [for search]: ', error);
    }
  }
}

function renderAFSPlaceholder(placementId: PlacementId) {
  setTimeout(() => {
    const slotElement = document.getElementById(placementId);

    if (slotElement) {
      const placeholder = document.createElement('div');
      Object.assign(placeholder.style, {
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '10px',
        width: '100%',
        height: '396px',
        lineHeight: '396px',
        textAlign: 'center'
      });

      placeholder.textContent = 'Adsense placeholder';
      slotElement.innerHTML = '';
      slotElement.appendChild(placeholder);
    }
  }, 50);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterAfsPlacements = filterAfsPlacements;
exports.filterGamPlacements = filterGamPlacements;
exports.filterAdnPlacements = filterAdnPlacements;
exports.filterXandrPlacements = filterXandrPlacements;
var adConfig_1 = require("./types/api/adConfig");
function filterAfsPlacements(placements) {
    return placements.filter(function (placement) { return placement.adServer.type === adConfig_1.AdServer.AFS; });
}
function filterGamPlacements(placements) {
    return placements.filter(function (placement) { return placement.adServer.type === adConfig_1.AdServer.GAM; });
}
function filterAdnPlacements(placements) {
    return placements.filter(function (placement) { return placement.adServer.type === adConfig_1.AdServer.Adn; });
}
function filterXandrPlacements(placements) {
    return placements.filter(function (placement) { return placement.adServer.type === adConfig_1.AdServer.Xandr; });
}

import { $config } from '@client/core/atoms/config';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

export function shouldRenderSponsoredContent(placementId: PlacementId) {
  const { brand, vertical, subvertical } = $config.get();

  const isRealEstate = vertical === 'realestate';
  const isRealEstateSubverticalMatch =
    isRealEstate && (subvertical === 'homes' || subvertical === 'newbuildings');
  const isCar = vertical === 'car';
  const isCarSubverticalMatch = isCar && subvertical === 'used';
  const sponsoredContentEnabledSubVertical =
    isRealEstateSubverticalMatch || isCarSubverticalMatch;
  const sponsoredContentEnabledBrand = brand === 'finn';
  const sponsoredContentEnabledPlacement = placementId?.includes('board_2');
  return (
    sponsoredContentEnabledSubVertical &&
    sponsoredContentEnabledBrand &&
    sponsoredContentEnabledPlacement
  );
}

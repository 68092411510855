import React from 'react';

import { createRoot } from 'react-dom/client';

import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

import GamAdUnit from './GamAdUnit';

export function renderGamAd(props: {
  containerId: string;
  placementId: PlacementId;
  initialLayoutType?: string;
}) {
  advtPerformance.markGamEvents(`renderAd`, props.containerId);

  const element = document.getElementById(props.containerId);
  const placementElement = element != null && createRoot(element);
  if (placementElement) {
    placementElement.render(<GamAdUnit {...props} />);
  }
}

import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import {
  debugLog,
  ExternalIdEid,
  getKeywordByKey,
  PPIDObject,
  SizePairs,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { $config, $ppidAtom } from '@client/core/atoms/config';

import { getBrandCode } from './externalId';
import {
  adSizePairsToSizes,
  adSizesToPairs,
  getRightSkyscraperSizes
} from './processAdSizes';
import { getConfigId } from './relevantDigitalConfig';

// eslint-disable-next-line consistent-return
export function loadTagsWithRelevantDigitalPrebid(params = {}) {
  const disableRelevantDigital = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.disableRelevantDigital
  );
  const enableGamPrebid = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamPrebid
  );

  if (disableRelevantDigital || enableGamPrebid) {
    window.apntag.loadTags();
  } else if (typeof window !== 'undefined') {
    window.relevantDigital = window.relevantDigital || {};
    window.relevantDigital.cmd = window.relevantDigital.cmd || [];

    const configId = configIdByBrand();
    if (!configId) return window.apntag.loadTags();

    let cancel; // if true => we've timed out and "emergency-call" to apntag.loadTags() has already been done
    const events: any = {
      relevantCb: { timeout: 1000 }, // Wait up to 1 second for the Relevant script
      auctionCb: { timeout: 2000 } // Wait up to 2 seconds for the auction to complete
    };

    const failsafeOnTimeout = (ev) =>
      setTimeout(() => {
        if (!events[ev].done) {
          console.log(
            `Doing failsafe loadTags() call after waiting too long for '${ev}'`
          );
          cancel = true;

          window.apntag.loadTags();
        }
      }, events[ev].timeout);

    failsafeOnTimeout('relevantCb');

    window.relevantDigital.cmd.push(async () => {
      if (cancel) {
        // We were too slow -  do nothing
        return;
      }
      events.relevantCb.done = true;
      failsafeOnTimeout('auctionCb');

      window.relevantDigital.addPrebidConfig({
        // s2sConfig: { syncEndpoint: null }, // Don't use PBS cookie syncing
        enableTIDs: true,
        enableSendAllBids: false, // Only send key-values for *winning* bids (HB_PB etc)
        userSync: getFormattedUserIdObject($ppidAtom.get().xandr),
        ortb2: getOrtb2Data()
      });

      window.relevantDigital.loadPrebid({
        configId,
        manageAdserver: false,
        loadUnknownSlots: true, // Load placements/tags not added in Yield
        noSlotReload: true, // Don't reload placements that has already been loaded
        noGpt: true, // Don't load gpt.js as it's done above
        onSlotAndUnit: isFeatureEnabled(
          UNLEASH_FEATURE_NAME.enableRelevantRight1DynamicSizes
        )
          ? ({
              slot,
              unit
            }: {
              slot: {
                divId: string;
              };
              unit: {
                apnIds: string[];
                pbAdUnit: {
                  sizes: SizePairs;
                };
              };
            }) => {
              debugLog('Ad Slot', slot);
              debugLog('Relevant Yield placement', unit);

              if (slot.divId?.includes('right_1')) {
                const right1Sizes = getRightSkyscraperSizes(
                  unit.pbAdUnit?.sizes.map(adSizePairsToSizes)
                );
                const sizesAsPairs = adSizesToPairs(right1Sizes);
                if (sizesAsPairs?.length) {
                  unit.pbAdUnit.sizes = sizesAsPairs;
                }
              }
            }
          : undefined,
        apntagCalls: {
          showTag: () => {
            /** Skip, we're doing this ourselves */
          },
          loadTags: (...args) => {
            if (!cancel) {
              if (window.apntag && window.apntag.anq) {
                window.apntag.anq.push(() => {
                  events.auctionCb.done = true;
                  advtPerformance.markXandrEvents(
                    `relevant digital loadTags callback(${JSON.stringify(
                      args
                    )})`
                  );
                  debugLog('Relevant Digital loadTags callback', args);
                  window.apntag.loadTags();
                });
              }
            } // else => We were too slow - do nothing
          }
        },
        ...params
      });
    });
  }
}

// Get config ID by brand and device type
function configIdByBrand() {
  const { brand = 'tori', deviceType = 'desktop' } = $config.get();
  return getConfigId(brand, deviceType);
}

function getOrtb2Data() {
  const { keywords } = $config.get().adServer.xandr?.pageOpts || {};
  if (!keywords) return undefined;
  const sectionData = getKeywordByKey(keywords, 'nmp-vertical');

  if (sectionData) {
    return {
      site: {
        ext: {
          data: {
            'nmp-vertical': sectionData
          }
        }
      }
    };
  }
  return undefined;
}

function getFormattedUserIdObject(persistedPPID: PPIDObject) {
  const eids: ExternalIdEid[] = [];
  const { brand } = $config.get();

  if (persistedPPID.ppId1) {
    eids.push({
      source: `SCH${getBrandCode(brand)}-UserHash`,
      uids: [
        {
          id: persistedPPID.ppId1, // PPID1
          atype: 1,
          ext: {
            stype: 'ppuid'
          }
        }
      ]
    });
  }

  if (persistedPPID.ppId2) {
    eids.push({
      source: `SCH${getBrandCode(brand)}-EnvHash`,
      uids: [
        {
          id: persistedPPID.ppId2, // PPID2
          atype: 1,
          ext: {
            stype: 'ppuid'
          }
        }
      ]
    });
  }
  return {
    userIds: [
      {
        name: 'pubProvidedId',
        params: {
          eids
        }
      }
    ]
  };
}

import React, { useEffect, useMemo, useState } from 'react';

import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures';
import { ConsentStatus, PlacementId } from '@schibsted-nmp/advertising-shared';
import { $config } from '@client/core/atoms/config';

type FancyCheckboxProps = {
  label: string;
  checked: boolean;
  name: string;
  id: string;
  onClick: () => void;
  disabled?: boolean;
};
const FancyCheckbox = ({
  label,
  checked,
  name,
  id,
  onClick,
  disabled
}: FancyCheckboxProps) => (
  <div
    style={checked ? { background: 'lightgreen', color: 'black' } : {}}
    className="flex items-center justify-between gap-2 p-2 shadow-md cursor-pointer hover:text-bold"
    onClick={!disabled ? onClick : undefined}
    role="checkbox"
    aria-checked={checked}
    tabIndex={0}
  >
    <label htmlFor={id} className="flex-grow cursor-pointer">
      {label}
    </label>
    <input
      type="checkbox"
      name={name}
      id={id}
      checked={checked}
      onChange={() => null}
      className="hidden"
    />
    <div className="text-right">{checked && '✅'}</div>
  </div>
);
const defaultAdId = '21306';
const DevPanel = () => {
  const config = $config.get();
  const { placements, forecastingPlacements } = $config.get();
  const url = new URL(window.location.href);
  const [hidePanel, setHidePanel] = useState(false);
  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState<string[]>([]);
  const consent = config?.consent;
  const errorList = useMemo(
    () =>
      [
        placements?.length === 0
          ? "No advertising loaded because server didn't return any placement"
          : null
      ].filter((e) => !!e),
    []
  );
  useEffect(() => {
    const sP = url.searchParams;
    const _pageType = sP.get('pageType');
    const _finnKode = sP.get('finnKode');
    if (_pageType === 'object' && !_finnKode) {
      toggleSearchParamAndReload('finnKode', defaultAdId, { noDelete: true });
    }
    const enabledFeatures = Object.keys(getAllFeatureStatuses()).filter(
      (feature) => getAllFeatureStatuses()[feature]
    );
    setEnabledFeatureFlags(enabledFeatures);
  }, []);

  const toggleSearchParamAndReload = (
    key: string,
    value: string,
    options: { noDelete?: boolean } = {}
  ) => {
    if (!options.noDelete && url.searchParams.get(key)) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
    window.location.href = url.href;
  };
  const showPlacement = (placementId: PlacementId) => {
    const el = document.getElementById(placementId);
    if (el) {
      console.log(`Found ${placementId}. Scrolling to it!`);
      el.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log(
        `No placement with id ${placementId}. Could it be a NO BID or another error?`
      );
    }
  };
  function getInfo() {
    const config = $config.get();
    const xandr = config?.adServer.xandr;
    const pageType = config?.pageType;
    const deviceType = config?.deviceType;
    const brand = config?.brand;
    const member = xandr?.pageOpts.member;
    return `${member} - ${brand} - ${deviceType} - ${pageType}`;
  }
  return (
    <div
      className="fixed top-0 left-0 border border-2 flex flex-col p-2 gap-8 z-30 overflow-auto max-h-screen"
      style={{ resize: 'both', overflow: 'auto', background: 'white' }}
    >
      <button
        className="text-12"
        style={{ width: 40 }}
        onClick={() => setHidePanel(!hidePanel)}
      >
        {hidePanel ? 'Show' : 'Hide'}{' '}
      </button>
      {hidePanel ? null : (
        <>
          <div className="flex flex-col gap-4">{getInfo()}</div>
          <FancyCheckbox
            id="ast_debug"
            name="ast_debug"
            checked={Boolean(url.searchParams.get('ast_debug'))}
            onClick={() => toggleSearchParamAndReload('ast_debug', 'true')}
            label="AST Debug"
          ></FancyCheckbox>
          <FancyCheckbox
            id="advt_debug"
            name="advt_debug"
            checked={Boolean(url.searchParams.get('advt_debug'))}
            onClick={() => toggleSearchParamAndReload('advt_debug', 'true')}
            label="ADVT Debug"
          ></FancyCheckbox>
          <FancyCheckbox
            id="ast_dongle"
            name="ast_dongle"
            checked={Boolean(url.searchParams.get('ast_dongle'))}
            onClick={() => toggleSearchParamAndReload('ast_dongle', 'yellow')}
            label="AST Console"
          ></FancyCheckbox>
          <FancyCheckbox
            id="ast_dongle"
            name="ast_dongle"
            checked={Boolean(url.searchParams.get('google_console'))}
            onClick={() => toggleSearchParamAndReload('google_console', '1')}
            label="GAM Console"
          ></FancyCheckbox>
          {!!errorList?.length && <h5>Errors:</h5>}
          <ul className="text-14 flex flex-col gap-4" style={{ maxWidth: 200 }}>
            {errorList.map((errorMsg) => (
              <li key={errorMsg} className="s-bg-primary p-4 break-all">
                {errorMsg}
              </li>
            ))}
          </ul>
          {!!placements?.length && <h5>Placements:</h5>}{' '}
          <div
            className="flex flex-col gap-2 overflow-scroll"
            style={{ height: '50vh' }}
          >
            <ul>
              {[...forecastingPlacements, ...placements]?.map((placement) => (
                <li
                  key={placement?.placementId}
                  id={`${placement?.placementId}-local-entry`}
                  onClick={() => showPlacement(placement?.placementId)}
                  className="cursor-pointer select-none rounded-4 p-4 break-all"
                >
                  {placement?.placementId}
                </li>
              ))}
            </ul>
            <div className="flex justify-between items-center mb-2 mt-8">
              <h5 className="mb-0">Consent for advertising:</h5>
            </div>
            {consent === ConsentStatus.Accepted && (
              <p className="text-center s-bg-positive-subtle">Accepted</p>
            )}
            {consent === ConsentStatus.Rejected && (
              <p className="text-center s-bg-warning-subtle">Rejected</p>
            )}
            {enabledFeatureFlags.length === 0 ? (
              <div>No enabled feature flags</div>
            ) : (
              <div>
                Enabled feature flags:
                {enabledFeatureFlags.map((feature) => (
                  <p
                    style={{
                      background: '#AFE1AF',
                      padding: '1px 4px',
                      borderRadius: 4,
                      width: 'fit-content'
                    }}
                  >
                    {feature}
                  </p>
                ))}
              </div>
            )}
          </div>
        </>
      )}{' '}
    </div>
  );
};
export default DevPanel;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnleashFeatureManager = void 0;
var featureNames_1 = require("./featureNames");
var UnleashFeatureManager = /** @class */ (function () {
    function UnleashFeatureManager(features) {
        this.features = features;
    }
    UnleashFeatureManager.prototype.isEnabled = function (featureName) {
        var _a;
        if (!this.features || Object.keys(this.features).length === 0)
            return false;
        return ((_a = this.features[featureName]) === null || _a === void 0 ? void 0 : _a.enabled) || false;
    };
    UnleashFeatureManager.prototype.getAllFeatureStatuses = function () {
        var _this = this;
        return featureNames_1.UNLEASH_FEATURE_ARRAY.reduce(function (acc, featureName) {
            var _a, _b;
            acc[featureName] = (_b = (_a = _this.features[featureName]) === null || _a === void 0 ? void 0 : _a.enabled) !== null && _b !== void 0 ? _b : false;
            return acc;
        }, {});
    };
    return UnleashFeatureManager;
}());
exports.UnleashFeatureManager = UnleashFeatureManager;

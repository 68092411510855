import cn from 'classnames';
import { html, LitElement, PropertyValues } from 'lit';

import { listenKeys } from '@podium/store';
import {
  AdServer,
  debugLog,
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';
import { AdSwitchProps } from '@client/xandr/components/adSwitch/AdSwitchComponent';
import { AdVendor } from '@client/core/AdVendor';
import { GamAdVendor } from '@client/adManager/GamAdVendor';
import { AfsAdVendor } from '@client/adsense/AfsAdVendor';
import { XandrAdVendor } from '@client/xandr/XandrAdVendor';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';
import { $placementsMap } from '@client/core/atoms/placements';
import { EmptyAdVendor } from '@client/core/EmptyAdVendor';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';
import { $config } from '@client/core/atoms/config';
import { events, messageBus } from '@schibsted-nmp/advertising-events';

import AdvtComponentStyles, {
  getClassNameByStatus,
  getClassNameByType
} from './AdvtComponentStyles';

const NAME = 'advt-component';

class AdvtComponent extends LitElement implements AdSwitchProps {
  // Properties with default values or required indicators
  containerId: string;

  placementId: PlacementId;

  adIndex?: number;

  categories?: RecommendationCategories;

  initialLayoutType?: LayoutType;

  adVendorInstance: InstanceType<typeof AdVendor>;

  private _adTypeClassName: string;

  private _originalClassName: string;

  private _statusClassName: string;

  private _typeClassName: string;

  static get properties() {
    return {
      containerId: { type: String },
      placementId: { type: String },
      initialLayoutType: { type: String },
      adIndex: { type: Number },
      categories: { type: Object }
    };
  }

  constructor() {
    super();
    this.placementId = PlacementId.Empty;
    this.containerId = ``;
    this.adIndex = 0;
    this._adTypeClassName = '';
    this._originalClassName = this.className;
    this._statusClassName = '';
    this._typeClassName = '';
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.adVendorInstance.cleanupEvents();
  }

  private static instanceRegistry: Map<string, AdvtComponent> = new Map();

  connectedCallback(): void {
    // TODO: This can be removed when item-page fixes their recommendation re-render stuff
    const { pageType } = $config.get();
    if (
      pageType === 'object' &&
      AdvtComponent.instanceRegistry.has(this.placementId)
    ) {
      console.warn(
        `AdvtComponent with placementId: ${this.placementId} already exists. Skipping render.`
      );
      return;
    }

    // Register the instance
    AdvtComponent.instanceRegistry.set(this.placementId, this);

    super.connectedCallback();
    this.initAdVendorInstance();

    listenKeys(
      $placementsMap,
      [`${this.placementId}.status`, `${this.placementId}.creativeType`],
      (placements) => {
        const placement =
          typeof placements === 'object' && placements[this.placementId];
        if (!placement) {
          return;
        }
        debugLog(
          `AdvtComponent: ${this.placementId} status/creativeType changed: ${placement.status}/${placement.creativeType}`
        );

        if (placement.creativeType) {
          this._typeClassName = getClassNameByType(placement.creativeType);
        }
        if (placement.status !== 'pending') {
          this._statusClassName = getClassNameByStatus(placement.status);
        }

        // Workaround to make SlotComponent hide. I couldn't do it with listenKeys
        if (placement.status === 'error') {
          messageBus.publish(
            events.PODLET.channel,
            'hide-slot-ad-container',
            this.placementId
          );
        }
        this.className = cn(
          this._originalClassName,
          this._adTypeClassName,
          this._statusClassName,
          this._typeClassName
        );
      }
    );

    this.adVendorInstance.setupEvents();
  }

  private initAdVendorInstance() {
    const placement = $placementsMap.get()[this.placementId];

    if (!placement) {
      debugLog('initAdVendorInstance failed. Missing placement from config');
      this.adVendorInstance = new EmptyAdVendor(this.placementId);
      this.className = 'advt-empty';
      sendErrorMessageMetricToServer(
        `Missing placement config for ${this.placementId} while advt-component exists`
      );
      return;
    }
    if (placement.adServer.type === AdServer.GAM) {
      this.adVendorInstance = new GamAdVendor(this.placementId);
      this._adTypeClassName = 'advt-g';
      this.className = this._adTypeClassName;
    } else if (placement.adServer.type === AdServer.AFS) {
      this.adVendorInstance = new AfsAdVendor(this.placementId);
      this.adVendorInstance.placementId = this.placementId;
      this._adTypeClassName = 'advt-g';
      this.className = this._adTypeClassName;
    } else if (placement.adServer.type === AdServer.Adn) {
      this.adVendorInstance = new AdnAdVendor(this.placementId);
      this._adTypeClassName = 'advt-adn';
      this.className = this._adTypeClassName;
    } else {
      this.adVendorInstance = new XandrAdVendor(
        this.placementId,
        this.containerId,
        this.adIndex || 0,
        this.initialLayoutType || 'list',
        this.categories
      );
      this._adTypeClassName = 'advt-x';
      this.className = this._adTypeClassName;
    }
    debugLog(
      `AdvtComponent: Using ${this.adVendorInstance.adServer} for ${this.placementId}`
    );
  }

  private makeSlotAvailableFromLightDOM(placementId: PlacementId) {
    const containerId =
      this.adVendorInstance.adServer === AdServer.AFS
        ? placementId
        : `${placementId}--container`;

    if (this.querySelector(`#${containerId}`)) {
      // If the container is already in the surrounding DOM, do nothing. This
      // check makes the component backwards compatible when the slotted
      // container is added by the component user.
      //
      // This is necessary because the component is used in two ways:
      // 1. The component user adds the container to the light DOM. This is the
      //    recommended way of using the component. One reason for this is to avoid
      //    hydration mismatch issues when using the component with React SSR.
      // 2. The component user does not add the container to the light DOM.
      //    This is the legacy way of using the component. The component adds the
      //    container to the light DOM itself, in order to be backwards compatible.
      return;
    }

    const slotDiv = document.createElement('div');
    slotDiv.slot = `${placementId}--slot`;
    slotDiv.id = containerId;

    // add the div with slot available in the light DOM
    this.appendChild(slotDiv);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.makeSlotAvailableFromLightDOM(this.adVendorInstance.placementId);
    if (
      this.adVendorInstance.adServer === AdServer.Xandr ||
      this.adVendorInstance.adServer === AdServer.GAM ||
      this.adVendorInstance.adServer === AdServer.Adn
    ) {
      this.adVendorInstance.requestAd();
    }
  }

  static styles = AdvtComponentStyles;

  render() {
    if (this.adVendorInstance) {
      return this.adVendorInstance.render();
    }
    return html``;
  }
}
export function defineAdvtComponent() {
  if (!customElements.get(NAME)) {
    customElements.define(NAME, AdvtComponent);
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdServer = void 0;
var AdServer;
(function (AdServer) {
    AdServer["AFS"] = "afs";
    AdServer["Xandr"] = "xandr";
    AdServer["GAM"] = "gam";
    AdServer["Adn"] = "adn";
    AdServer["SponsoredContent"] = "sponsored-content";
})(AdServer || (exports.AdServer = AdServer = {}));

import { initiateGam } from '@client/adManager/initiateGam';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';
import { initiateAdn } from '@client/adnuntius/initiateAdn';
import { initiateHandleStickySkyscrapers } from '@client/core/utils/dom/handleAdsOverlap/handleStickySkyscrapers';
import { setupEvents } from '@client/core//utils/events';
import { setupOverrideHelper } from '@client/core//utils/overrideHelper';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import {
  AdServer,
  debugLog,
  hasQueryParam,
  InitialState
} from '@schibsted-nmp/advertising-shared';
import { persistPPID } from '@client/core/utils/pulse/pulse';
import { defineAdvtComponent } from '@client/core/components/webComponents';
import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';
import { getConsentOrSubscribe } from '@client/core/utils/consent';

import { setPageOptsInXandr } from './xandr/setPageOpts';
import { loadGlimrIfPermission } from './core/utils/glimr';
import { initFeed } from './xandr/feed';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  try {
    advtPerformance.markXandrEvents(`initiateAdvt`);
    advtPerformance.markXandrEvents(
      `Expect placements: ${initialState?.config?.placements
        ?.map((p) => p.placementId)
        .join(', ')}`
    );

    debugLog('Inside initiateAdvt()');

    const { config, env } = initialState;

    // set meta tag to header
    const meta = document.createElement('meta');
    meta.httpEquiv = 'delegate-ch';
    meta.content =
      'sec-ch-ua-model https://ib.adnxs.com; sec-ch-ua-platform-version https://ib.adnxs.com';
    document.head.appendChild(meta);

    if (env === 'local' || hasQueryParam('advt_console')) {
      debugLog('Rendering dev panel');
      const { renderDevPanel } = await import('./local');
      renderDevPanel();
    }

    await Promise.all([
      await persistPPID(),
      loadGlimrIfPermission(config.brand)
    ]);

    if (config.adServer.xandr) {
      getConsentOrSubscribe((permissionValue) => {
        setPageOptsInXandr(permissionValue);
      });
    }

    setupEvents();

    if (config?.adServer.xandr) {
      setupOverrideHelper(
        config.placements.filter((p) => p.adServer.type === AdServer.Xandr)
      );
    }

    if (config.deviceType === 'desktop') {
      initiateHandleStickySkyscrapers();
    }

    if (config.brand?.toLowerCase() === 'finn') {
      if (config?.adServer?.xandr?.feed) {
        debugLog('Setting up feed');
        initFeed(config.adServer.xandr.feed);
      }

      triggerFinnAfsRequest();
    }

    defineAdvtComponent();

    if (config?.adServer.gam) {
      initiateGam(config.adServer.gam);
    }

    if (config?.adServer.adn) {
      initiateAdn(config.adServer.adn);
      AdnAdVendor.setupBatchRequesting();
    }
    debugLog('Advt initiated');
  } catch (e) {
    console.error('Failed to initiate ads', e);
    sendErrorMessageMetricToServer(`Failed to initiate ads ${e.message}`).then(
      () => {
        debugLog('Sent error message metric to server');
      }
    );
  }
}

import React from 'react';

import { createRoot } from 'react-dom/client';

import { advtPerformance } from '@schibsted-nmp/advertising-events';
import {
  debugLog,
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { RenderSponsoredContentOrChildren } from '@client/sponsoredContent/RenderSponsoredContentOrChildren';
import { shouldRenderSponsoredContent } from '@client/sponsoredContent/shouldRenderSponsoredContent';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';

import { AdSwitchComponent, AdSwitchProps } from './adSwitch/AdSwitchComponent';
import ForecastingGamAdUnit from '../../adManager/AdUnit/ForecastingGamAdUnit';

export function renderAd(props: AdSwitchProps) {
  advtPerformance.markXandrEvents(`renderAd`, props.containerId);

  const containerElement = document.getElementById(props.containerId);
  if (containerElement) {
    if (containerElement.querySelector(`#${props.placementId}`)) {
      debugLog(`Ad already rendered for ${props.placementId}`);
      return;
    }

    const placementElement = createRoot(containerElement);
    if (placementElement) {
      const forecastingEnabled = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.enableGamForecasting
      );

      const Children = (
        <>
          {forecastingEnabled && (
            <ForecastingGamAdUnit
              containerId={props.containerId}
              placementId={`forecasting-${props.placementId}` as PlacementId}
            />
          )}{' '}
          <AdSwitchComponent {...props} />
        </>
      );

      if (shouldRenderSponsoredContent(props.placementId)) {
        placementElement.render(
          <RenderSponsoredContentOrChildren {...props}>
            {Children}
          </RenderSponsoredContentOrChildren>
        );
      } else {
        placementElement.render(Children);
      }
    }
  } else {
    debugLog(`Container element with id ${props.containerId} not found`);
  }
}

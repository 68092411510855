import React from 'react';

import {
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { useInitiateGamUnit } from '@client/adManager/AdUnit/useInitiateGamUnit';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';

type Props = {
  containerId: string;
  placementId: PlacementId;
};
function ForecastingGamAdUnit({ containerId, placementId }: Props) {
  const ref = React.createRef<HTMLDivElement>();

  const hasTestCampaignFlag = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamTestCampaign
  );
  useInitiateGamUnit({
    containerId,
    placementId,
    ref,
    forecasting: true
  });

  return (
    <div
      style={
        hasTestCampaignFlag
          ? {}
          : {
              opacity: 0,
              width: 1,
              height: 1,
              position: 'absolute'
            }
      }
      ref={ref}
      id={placementId}
    />
  );
}

export default ForecastingGamAdUnit;

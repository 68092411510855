import { atom } from '@podium/store';
import {
  PlacementStatuses,
  PlacementStatusMetric
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from '.';

export const $placementStatusMetrics = atom<PlacementStatusMetric[]>(
  AtomChannels.Podlet,
  AtomTopics.PlacementStatusMetrics,
  []
);

export function updateMetricByKey(placementId: string, key: PlacementStatuses) {
  const oldMetrics = $placementStatusMetrics.get();
  const updatedMetrics = oldMetrics.map((oldMetric) => {
    if (oldMetric.placementId === placementId) {
      return {
        ...oldMetric,
        statuses: [...oldMetric.statuses, key]
      };
    }
    return oldMetric;
  });
  $placementStatusMetrics.set(updatedMetrics);
}

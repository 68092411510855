import React from 'react';

import { Native } from '@schibsted-nmp/advertising-shared';

import { escapeString } from '../native.helper';

type Props = {
  native: Native;
  className?: string;
  margin?: string;
  // We always set either width or height, to let the image grow naturally the other way
  constrainWidth?: boolean;
};
function NativeLogo(props: Props) {
  const { native, className, constrainWidth } = props;
  let logoUrl: string;
  if (native && native.icon && native.icon.url) {
    logoUrl = escapeString(native.icon.url);
  } else {
    return null;
  }

  const altText = escapeString(native.sponsoredBy ? native.sponsoredBy : '');

  const wideOrTallStyle = constrainWidth
    ? { width: 48, minWidth: 48 }
    : { height: 48, minHeight: 48 };

  return (
    <img
      src={logoUrl}
      className={className}
      style={wideOrTallStyle}
      alt={altText}
      width={constrainWidth ? 48 : ''}
      height={!constrainWidth ? 48 : ''}
    />
  );
}

export default NativeLogo;

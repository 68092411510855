import { debounce } from 'throttle-debounce';

import { $placementStatusMetrics } from '@client/core/atoms/metrics';
import fetcher from '@client/core/utils/api/fetcher';
import { $config } from '@client/core/atoms/config';
import {
  AdServer,
  ErrorMetricsBody,
  PlacementStatusMetricsBody
} from '@schibsted-nmp/advertising-shared';

const debouncedSendMetricsFunction = debounce(2000, (adServer: AdServer) => {
  sendMetricsToServer(adServer);
});

export function debouncedSendMetrics(adServer: AdServer) {
  debouncedSendMetricsFunction(adServer);
}
export function getDebouncedSendMetricsFunction() {
  return debouncedSendMetrics;
}

export async function sendMetricsToServer(adServer: AdServer) {
  const placementStatuses = $placementStatusMetrics.get();
  const { pageType, deviceType, vertical, subvertical } = $config.get();
  // send metrics to server
  try {
    fetcher('/api/metrics/placement-statuses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        placementStatuses,
        deviceType,
        pageType,
        adServer,
        vertical,
        subvertical
      } as PlacementStatusMetricsBody)
    });
  } catch (e) {
    console.error('Failed to send metrics to server', e);
  }
}

export async function sendErrorMessageMetricToServer(errorMsg: string) {
  const { pageType, deviceType, vertical, subvertical } = $config.get();

  try {
    if (errorMsg) {
      fetcher(`/api/metrics/client-error`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          errorMessage: errorMsg,
          deviceType,
          pageType,
          vertical,
          subvertical
        } as ErrorMetricsBody)
      });
    }
  } catch (e) {
    console.error('Failed to send error message to server', e);
  }
}

"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasQueryParam = hasQueryParam;
exports.isDebug = isDebug;
exports.debugLog = debugLog;
var safeSetAdvtState_1 = require("./safeSetAdvtState");
var ENABLED_PARAM = 'advt_debug';
var isDebugMode;
function hasQueryParam(param) {
    return new URL(window.location.href).searchParams.get(param) !== null;
}
function initializeIsDebugMode() {
    var localStorageHasDebugFlag = window.localStorage.getItem(ENABLED_PARAM) === 'true';
    return localStorageHasDebugFlag || hasQueryParam(ENABLED_PARAM);
}
(0, safeSetAdvtState_1.setAdvtState)('toggleDebug', function () {
    var localStorageHasDebugFlag = window.localStorage.getItem(ENABLED_PARAM) === 'true';
    if (localStorageHasDebugFlag) {
        window.localStorage.removeItem(ENABLED_PARAM);
    }
    else {
        window.localStorage.setItem(ENABLED_PARAM, 'true');
    }
});
function isDebug() {
    if (isDebugMode === undefined) {
        isDebugMode = initializeIsDebugMode();
    }
    return isDebugMode;
}
var config = {
    name: 'advertising-podlet',
    primaryColor: 'skyblue',
    secondaryColor: 'royalblue'
};
function debugLog() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (!isDebug())
        return;
    console.log.apply(console, __spreadArray(["%c ".concat(config.name), "color:".concat(config.primaryColor, "; background:").concat(config.secondaryColor)], args, false));
}

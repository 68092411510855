import React from 'react';

import classNames from 'classnames';

import { useCycleAd, useSetupAd } from '@client/core/hooks';
import {
  BannerAdObj,
  isBannerAd,
  isNativeAd,
  isPremiumNativeAd,
  isTakeover,
  isVideoAd,
  LayoutType,
  NativeAdObj,
  PlacementId,
  RecommendationCategories,
  VideoAdObj
} from '@schibsted-nmp/advertising-shared';
import { Placeholder } from '@client/core/components/placeholder/Placeholder';
import useLayoutTypeSub from '@client/core/hooks/useLayoutTypeSub';
import NativePremiumCpmComponent from '@client/xandr/components/native/NativePremiumCpmComponent';
import { $config } from '@client/core/atoms/config';

import BannerAdComponent from '../banner/BannerAdComponent';
import NativeAdComponent from '../native/NativeAdComponent';
import VideoAdComponent from '../video/VideoAdComponent';
import TakeoverAdComponent from '../takeover/TakeoverAdComponent';
import { WallpaperAdComponent } from '../wallpaper/WallpaperAdComponent';

export type AdSwitchProps = {
  containerId: string;
  placementId: PlacementId;
  initialLayoutType?: LayoutType;
  categories?: RecommendationCategories;
};

export function AdSwitchComponent(props: AdSwitchProps) {
  const { deviceType } = $config.get();
  const { placementId, containerId, initialLayoutType, categories } = props;
  const targetId = useCycleAd(placementId);
  const layoutType = useLayoutTypeSub(initialLayoutType || 'grid');

  const { adObj } = useSetupAd({
    placementId,
    targetId,
    containerId,
    categories
  });

  if (!adObj) return null;
  if (isTakeover(adObj, deviceType)) {
    return (
      <TakeoverAdComponent
        placementId={placementId}
        targetId={targetId}
        adObj={adObj}
      />
    );
  }
  if (placementId === 'wallpaper') {
    return (
      <WallpaperAdComponent placementId={placementId} targetId={targetId} />
    );
  }

  if (isNativeAd(adObj)) {
    if (isPremiumNativeAd(adObj as NativeAdObj)) {
      return (
        <NativePremiumCpmComponent
          {...props}
          targetId={targetId}
          layoutType={layoutType}
          adObj={adObj as NativeAdObj}
        />
      );
    }
    return (
      <NativeAdComponent
        adObj={adObj as NativeAdObj}
        targetId={targetId}
        placementId={placementId}
        layoutType={layoutType}
      />
    );
  }
  if (isBannerAd(adObj)) {
    return (
      <BannerAdComponent
        adObj={adObj as BannerAdObj}
        targetId={targetId}
        placementId={placementId}
        className={classNames('relative', {
          'ml-auto overflow-hidden': placementId === PlacementId.Bottom1,
          'mx-auto':
            placementId.includes('middle') || placementId.includes('board_')
        })}
      />
    );
  }
  if (isVideoAd(adObj)) {
    return (
      <VideoAdComponent
        adObj={adObj as VideoAdObj}
        targetId={targetId}
        placementId={placementId}
      />
    );
  }
  return <Placeholder targetId={targetId} />;
}

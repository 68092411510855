import React from 'react';

import { PlacementId } from '@schibsted-nmp/advertising-shared';
import { useInitiateGamUnit } from '@client/adManager/AdUnit/useInitiateGamUnit';
import { useIsBannerAdnamiAd } from '@client/xandr/components/banner/useBannerIsAdnami';

type Props = {
  containerId: string;
  placementId: PlacementId;
};
function GamAdUnit({ containerId, placementId }: Props) {
  const ref = React.createRef<HTMLDivElement>();

  useInitiateGamUnit({
    containerId,
    placementId,
    ref
  });
  const isAdnami = useIsBannerAdnamiAd(ref, placementId);

  return <div ref={ref} id={placementId} data-is-adnami={isAdnami} />;
}

export default GamAdUnit;

import React from 'react';

import classNames from 'classnames';

import { CustomLabel } from '@client/xandr/components/native/parts/Label';
import { i18n } from '@lingui/core';
import NativeLogo from '@client/xandr/components/native/parts/NativeLogo';
import { Props } from '@client/xandr/components/native/NativeAdComponent';
import {
  escapeString,
  getDescription
} from '@client/xandr/components/native/native.helper';

/**
 * Native ads on front and object pages are always recommendation ads.
 *
 * The reason this is not being retrieved from search-components is that there
 * are no search components matching this. Recommendations are creating their own components.
 *
 * We could improve this by checking on placementIDs instead of pageType,
 * if we create a util isRecommendationAd(placementId) but then it's string matching.
 */
export const RecommendationNativeAd = ({ adObj, pageType }: Props) => {
  const { native } = adObj;
  const adLabel = i18n.t({
    id: 'native.advertisement',
    message: 'advertisement'
  });

  const isFrontPage = pageType === 'front';

  return (
    <article
      className={classNames(
        'relative isolate hover:s-bg-primary-subtle focus:s-bg-primary-subtle rounded-8 h-full',
        {
          'card card--cardShadow': isFrontPage,
          'p-8': !isFrontPage
        }
      )}
    >
      <div
        className="absolute"
        aria-owns="search-ad-native-ad-stream__native-ad-1"
      ></div>
      <div className="mb-2">
        <div className="aspect-1/1">
          <img
            alt={native.title}
            className="w-full h-full object-center object-cover rounded-8 border s-border"
            src={native.image.url}
          />
        </div>
      </div>
      <div
        className={classNames('flex flex-col', {
          'mx-16 mt-6 mb-14': isFrontPage
        })}
      >
        <span className="text-s s-text-subtle">
          {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
        </span>
        <div className="flex justify-between items-center gap-2 w-full">
          <h2
            className="h4 mb-0 break-words"
            id="search-ad-native-ad-stream__native-ad-1"
          >
            <a
              className="sf-search-ad-link s-text! hover:no-underline"
              href={native.clickUrl}
              id={native.title}
            >
              <span className="absolute inset-0" aria-hidden="true"></span>
              {native.title}
            </a>
          </h2>
          <div>
            <NativeLogo constrainWidth={true} native={native} />
          </div>
        </div>

        <div className="">
          <span>{getDescription(native)}</span>
        </div>
      </div>
      <CustomLabel
        adLabel={adLabel}
        insetClassName={classNames({
          'top-0 left-0': isFrontPage,
          'top-8 left-8': !isFrontPage
        })}
      />

      <div className="absolute inset-0 pointer-events-none outline-none rounded-8 border-2 border-transparent sf-search-ad-outline"></div>
    </article>
  );
};

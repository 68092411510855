import { debounce } from 'throttle-debounce';

import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { Slot, UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import { loadRelevantDigitalPrebid } from '@client/core/relevantDigital/prebid';
import { $config } from '@client/core/atoms/config';

let pendingSlots: Slot[] = [];

export function loadBatchedAllSlots(slots: Slot[]) {
  window.googletag.cmd.push(() => {
    window.googletag.pubads().refresh(slots);
  });
}

// Debounce function that accumulates slots and refreshes them after 200ms
const debounceLoadAd = debounce(
  200,
  () => {
    const adConfig = $config.get();
    // Capture the pending slots
    const slotsToLoad = [...pendingSlots];
    // Clear the pending slots
    pendingSlots = [];
    // run prebid !!
    if (
      isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid) &&
      adConfig.brand === 'tori'
    ) {
      loadRelevantDigitalPrebid();
    } else {
      // Load the batched slots
      loadBatchedAllSlots(slotsToLoad);
    }
  },
  { atBegin: false }
);

export function debouncedLoadAstTags(slot: Slot) {
  // Add the slot to the pending list
  pendingSlots.push(slot);
  // Call the debounced function
  debounceLoadAd();
}

export function getDebouncedLoadAdFunction() {
  return debouncedLoadAstTags;
}

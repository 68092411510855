import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { $config } from '@client/core/atoms/config';
import { getInterminglePlacements } from '@client/core/atoms/placements';
import { debugLog } from '@schibsted-nmp/advertising-shared';

import { searchFilterCallback } from '../refreshKeywords';

export function setupEvents() {
  const { pageType } = $config.get();

  // Keep this for backward compatibility
  const interminglePlacements = getInterminglePlacements();
  debugLog('placementsWithIntermingle: ', interminglePlacements);

  if (interminglePlacements.length > 0) {
    messageBus.publish(
      events.PODLET.channel,
      events.PODLET.PODLET_READY.topic,
      events.PODLET.PODLET_READY.sendPayload({
        placementsWithIntermingle: interminglePlacements
      })
    );
  }

  if (pageType === 'result') {
    messageBus.subscribe(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      searchFilterCallback
    );
  }
}

import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import {
  debugLog,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import {
  getRefreshPlacements,
  resetRefreshPlacements
} from '@client/core/atoms/placements';
import { $gamTargetingAtom } from '@client/core/atoms/gamTargeting';

import { $config } from '../atoms/config';
import { getConfigId } from './config';

// eslint-disable-next-line consistent-return
export function loadRelevantDigitalPrebid() {
  const enableGamPrebid = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamPrebid
  );

  if (enableGamPrebid && typeof window !== 'undefined') {
    window.relevantDigital = window.relevantDigital || {};
    window.relevantDigital.cmd = window.relevantDigital.cmd || [];

    const configId = configIdByBrand();
    if (!configId) return;

    // At the moment this part of the code takes into account only mappings related to Tori
    // we can check at later stage as we implement other brands how to properly
    // separate mapping concerns if need be
    const gamTargeting = $gamTargetingAtom.get();

    const gamToXandrMapping = {
      recom_cat_1: 'nmp-recommerce-category_level_1',
      recom_cat_2: 'nmp-recommerce-category_level_2',
      recom_cat_3: 'nmp-recommerce-category_level_3'
    };

    // Create a new object with keys replaced where applicable
    const transformedTargeting = Object.entries(gamTargeting).reduce(
      (acc, [, value]) => {
        // Check if the key exists in gamToXandrMapping, if yes, replace the key
        const newKey = gamToXandrMapping[value.key] || value.key;
        // Add the new key-value pair to the result object
        acc[newKey] = value.value;
        return acc;
      },
      {}
    );

    window.relevantDigital.addPrebidConfig({
      appnexusAuctionKeywords: transformedTargeting
    });

    const allowedDivIds = getRefreshPlacements().map((p) => p.placementId);
    debugLog('Loading Relevant Digital Prebid for GAM', allowedDivIds);
    window.relevantDigital.cmd.push(() => {
      window.relevantDigital.loadPrebid({
        configId,
        manageAdserver: false,
        collapseEmptyDivs: true,
        noGpt: true,
        collapseBeforeAdFetch: false,
        noSlotReload: false, // Initially false for a full refresh
        allowedDivIds
      });
      resetRefreshPlacements();
    });
  }
}

// Get config ID by brand and device type
function configIdByBrand() {
  const config = $config.get() || {};
  const brand = config.brand || 'tori';
  const deviceType = config.deviceType || 'desktop';

  return getConfigId(brand, deviceType);
}
